 <template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="90px">
			<el-form-item class="el_form_item" label="货物编码">
				<el-input class="el_input" v-model="form.sku_code" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="货号">
				<el-input class="el_input" v-model="form.sku_num" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="品名规格">
				<el-input class="el_input" v-model="form.sku_name" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="转换后名称">
				<el-input class="el_input" v-model="form.transit_name" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="备注">
				<el-input class="el_input" v-model="form.mark" clearable></el-input>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="page_ser">查询</el-button>
				<el-button type="success" @click="add_view_open">添加</el-button>
				<!-- <el-button type="success" @click="daoru">导入</el-button> -->
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading">
				<el-table-column label="创建时间" prop="create_time_text" width="150"></el-table-column>
				<el-table-column label="修改时间" prop="update_time_text" width="150"></el-table-column>
				<el-table-column label="货物编码" prop="sku_code" width="70"></el-table-column>
				<el-table-column label="货号" prop="sku_num" width="70"></el-table-column>
				<el-table-column label="品名规格" prop="sku_name"></el-table-column>
				<el-table-column label="转换后" prop="transit_name"></el-table-column>
				<el-table-column label="单品重量(公斤)" prop="sku_weight" width="120"></el-table-column>
				<el-table-column label="单品体积(立方)" prop="sku_volume" width="120"></el-table-column>
				<el-table-column label="备注" prop="mark" width="150"></el-table-column>

				<!-- 操作行 -->
				<el-table-column label="操作" width="85">
					<template slot-scope="scope">
						<el-button @click="edit_view_open(scope.row)" size="mini" type="text">修改</el-button>
						<el-button @click="del(scope.row)" size="mini" type="text">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change" 
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]" 
			:page-size="page.num" 
			:total="list.total*1" 
			layout="total, sizes,prev, pager, next, jumper" 
		></el-pagination>

		<!-- 修改弹出层 -->
		<edit 
			:is_show="edit.is_show" 
			:data="edit.data" 
			@edited="edit_view_close" 
		></edit>

		<!-- 添加弹出层 -->
		<add 
			:is_show="add.is_show" 
			@added="add_view_close"
		></add>

	</div>
</template>

<script>
	import xlsx from 'xlsx'
	import add from './add.vue'
	import edit from './edit.vue'
	export default {
		components:{
			add,
			edit,
		},
		data() {
			return {

				//搜索条件
				form: {
					sku_code:'',
					sku_num:'',
					sku_name:'',
					transit_name:'',
					mark:'',
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},

				//添加弹窗
				add:{
					is_show:0,
				},

				//修改弹窗
				edit:{
					data:{},
					is_show:0,
				}
			}
		},
		created() {
			
			//读取用户组数据
			this.page_ser()
		},
		methods: {

			daoru(){
				this.$my.file.choose({
					accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					success: (res) => {

						let file_reader = new FileReader();
						file_reader.onload = (ev) => {
							
							let workbook = xlsx.read(ev.target.result, {
								type: 'binary'
							})

							//取出数据
							let file_data = xlsx.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]],{header:1,defval:''}) // 生成json表格内容
							
							this.data_import(file_data)

						}
						file_reader.readAsBinaryString(res.file)
					}
				})
			},

			data_import(file_data){

				let post_data=[];

				for(var i=4;i<file_data.length-5;i++){
					let item=file_data[i]
					post_data.push({
						sku_code:item[12],
						sku_num:item[0],
						sku_name:item[1],
						sku_weight:item[2],
						sku_volume:item[8],
					})
					post_data.push({
						sku_code:item[27],
						sku_num:item[15],
						sku_name:item[16],
						sku_weight:item[17],
						sku_volume:item[23],
					})
				}

				let post_data2=[]
				for(var item of post_data){
					if(!item.sku_code)continue
					if(!item.sku_name)continue
					if(!item.sku_weight)continue
					if(!item.sku_volume)continue
					item.sku_name=this.skunamechuli(item.sku_name,item.sku_num)
					item.transit_name=item.sku_name
					post_data2.push(item)
				}

				this.$my.net.req({
					method:'post',
					data:{
						mod:'sku',
						ctr:'sku_of_zhengdian_add2_by_admin',
						list:post_data2
					},
					callback:(data)=>{
						console.log(data)
					},
				});
			},
			skunamechuli(sku_name,sku_num){
				sku_name=sku_name.replace("3★",'')
				sku_name=sku_name.replace("★",'')
				sku_name=sku_name.replace("新",'')

				if(sku_num){
					sku_name=sku_name.replace(sku_num,'')
				}

				return sku_name.trim()
			},

			//删除用户组
			del(item){

				//询问
				this.$my.other.confirm({
					content:"是否删除此SKU",
					confirm:()=>{
						this.$my.net.req({
							data:{
								mod:'sku',
								ctr:'sku_of_zhengdian_del_by_admin',
								id:item.id
							},
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:'操作成功'
								});
								this.get_page_data()
							},
						});
					}
				});
			},

			//添加页面
			add_view_open(){
				this.add.is_show++
			},
			add_view_close(){
				this.add.is_show=0
				this.get_page_data()
			},

			//打开用户组修改页面
			edit_view_open(item){
				this.edit.data=item
				this.edit.is_show++
			},
			edit_view_close(item){
				this.edit.is_show=0
				this.get_page_data()
			},
			
			//搜索
			page_ser(){
				this.page.p=1
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){
				this.page.num=num
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				this.page.p=p
				this.get_page_data()
			},

			//读取用户组数据
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'sku',
						ctr:'sku_of_zhengdian_list_by_admin',
						...this.form,
						...this.page
					},
					callback:(data)=>{

						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){

							//创建时间
							item.create_time_text=this.$my.other.totime(item.create_time);

							//上次修改时间
							item.update_time_text=this.$my.other.totime(item.update_time);
						}

						//渲染
						this.list.data=data.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>

	//修改/添加表单的额外权限菜单部分
	.menu_list{
		background-color:#eee;
		height: 500px;
		overflow: auto;
		padding: 0 20px;
		margin-top: 10px;
		width: 90%;
		.children_list{
			margin-left: 20px;
		}
	}

	//其他
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 90px);
	}
	.el_form_item{
		width: 16.6672%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>